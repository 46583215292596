import { REQUIRED_FIELDS_IN_COMMENTS } from '../constants/pwa';

function getResponseErrorsList(err) {
    let data = err.response ? err.response.data : null;

    if (!data)
        return {"common": "Неизвестная ошибка"};

    if (!data.errors)
        return {"common": "Ошибка сервера"};

    if (Array.isArray(data.errors)) {
        return data.errors.reduce((errors, err) => {
            let field = err.param ? err.param : err.path;
            if (!field) field = 'common';
            return {...errors, ...{[field]: err.msg}};
        }, {});
    }

    if (typeof data.errors === 'object')
        return data.errors;

    return {"common": data.errors};
}

function getResponseError(err) {
    let data = err.response ? err.response.data : null;

    if (!data)
        return "Неизвестная ошибка";

    if (!data.errors)
        return "Ошибка сервера";

    return data.errors;
}

function formatRating(num) {
    if (num < 1) {
        return "1.0";
    }
    if (num > 5) {
        return "5.0";
    }
    return num.toFixed(1);
}

function formatDate(dateString) {
    const date = new Date(dateString);
    
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
}

function sortLanguages(languages) {
    return languages.sort((a, b) => a.label.localeCompare(b.label, "ru"));
}

function compareObjects(initialData, finishData, ignoresFields = []) {
    // Функция для фильтрации объекта, исключая ключи из ignoresFields
    const filterObject = (obj, ignores) => {
        if (typeof obj !== "object" || obj === null) return obj;

        return Object.keys(obj).reduce(
            (filtered, key) => {
                if (!ignores.includes(key)) {
                    filtered[key] = filterObject(obj[key], ignores);
                }
                return filtered;
            },
            Array.isArray(obj) ? [] : {}
        );
    };

    // Рекурсивная функция для сравнения объектов
    const deepCompare = (obj1, obj2) => {
        if (typeof obj1 !== typeof obj2) return false;
        if (typeof obj1 !== "object" || obj1 === null || obj2 === null) {
            return obj1 === obj2;
        }

        const keys1 = Object.keys(obj1);
        const keys2 = Object.keys(obj2);

        if (keys1.length !== keys2.length) return false;

        for (const key of keys1) {
            if (!keys2.includes(key) || !deepCompare(obj1[key], obj2[key])) {
                return false;
            }
        }

        return true;
    };

    // Фильтруем оба объекта
    const filteredInitialData = filterObject(initialData, ignoresFields);
    const filteredFinishData = filterObject(finishData, ignoresFields);

    // Глубокое сравнение объектов
    return deepCompare(filteredInitialData, filteredFinishData);
}

const validationPwaData = (data) => {
    const errors = [];
    if (!data.name) {
        errors.push({
            type: "filed",
            msg: "Поле 'Название' является обязательным.",
        });
    }

    if (errors.length) {
        return { response: { data: { errors } } };
    }
    let errorData;
    // check comments
    Object.keys(data.comments).forEach((lang) => {
        if (errorData) return;
        const comments = data.comments[lang];
        Object.keys(comments).forEach((commentId) => {
            if (errorData) return;
            const comment = comments[commentId];
            Object.keys(comment).forEach((fieldName) => {
                if (errorData) return;
                if (
                    REQUIRED_FIELDS_IN_COMMENTS.includes(fieldName) &&
                    !comment[fieldName]
                ) {
                    errorData = {
                        tab: "Комментарии",
                        lang,
                        commentId,
                        fieldName,
                        message: 'Обязательное поле'
                    };
                }
            });
        });
    });

    if (errorData) {
        return errorData;
    }
    return null;
};

export * from '../common/commonUtil';
export {
    getResponseErrorsList,
    getResponseError,
    formatRating,
    formatDate,
    sortLanguages,
    compareObjects,
    validationPwaData
}
