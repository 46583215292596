import React, { Component } from 'react';
import axios from "axios";
import {getResponseErrorsList,pick} from "../../util/util";
import {GameReleaseState, StoreID, FilterLevel, WhitePageType, OsType} from "../../common/commonTypes";
import {formatPercent,formatCurrency} from "../../util/util";
import {CopyToClipboard} from "react-copy-to-clipboard";
import { Tooltip as ReactTooltip } from "react-tooltip";
import queryString from "query-string";
import {UserContext, UserGroup} from "../../context/User";
import {Link, useLocation, useNavigate} from "react-router-dom";
import Select from 'react-select';
import AppTrafficDistribution from "./AppTrafficDistribution";
import TrafficDistribution from "./TrafficDistribution";
import CopyIcon from "../../components/icons/CopyIcon"
import Button from '../../components/UI/Button';
import Textarea from '../../components/UI/Textarea';

const OTHER_KEY = '__other__';
const POSTBACK_LINK = 'https://pb.deeplink.agency/{uid}/{event}/';
const FB_EVENTS = [
    { name: 'Регистрация', fbevent: 'register' },
    { name: 'Покупка', fbevent: 'purchase' },
    /*{ name: 'Подписка', fbevent: 'subscribe' },
    { name: 'Начало пробного периода', fbevent: 'start_trial' },
    { name: 'Расход. кредитов', fbevent: 'spent_credits' },*/
];
const POSTBACK_PARAM_OPTIONS = ['s5','sub5','sub_id_5','sub_id_14','sub_id_15','external_id'];

const adspectFilterDesc = {
    [FilterLevel.OFF]: 'Всех пропускать в открытых гео.',
    [FilterLevel.LOW]: 'Блокируются только самые основные угрозы: IT-корпорации, крупнейшие хостинги и датацентры, антивирусные компании и т.п.',
    [FilterLevel.MID]: 'Блокируются все IT-корпорации, датацентры, хостинги и провайдеры IP-транзита. Данный уровень безопасен для большинства ситуаций. Используйте его, если не знаете, какой уровень выбрать.',
    [FilterLevel.HIGH]: 'Как «Средний», плюс включает проверку принадлежности IP-сетей заблокированным организациям за последние 3 года. Также переходы проверяются по внешним базам данных и поставщикам риск-скоринга.'
};

const whitePageTypeDesc = {
    [WhitePageType.GOOGLE_PLAY]: {name: 'Google Play', desc: 'Страница, генерируемая на основании ссылки на приложение Google Play:'},
    [WhitePageType.APP_STORE]: {name: 'App Store', desc: 'Страница, генерируемая на основании ссылки на приложение App Store:'},
    [WhitePageType.WORD_PRESS]: {name: 'Wordpress', isTheme: true, desc: 'Многостраничный новостной блог на базе WordPress с определенной темой'},
    [WhitePageType.SAFE_PAGE]: {name: 'Safepage', isTheme: true, desc: 'Базовый новостной блог с определенной темой'}
};

const whitePageThemes = [
    'gaming','travel','music','food','science','energy','news','sport','beauty','tech',
    'world','finance','politics','business','economics','entertainment'
];

const getAllGeoOptions = (allGeo) => {
    let continentsMap = {};
    for (const geoData of allGeo) {
        if (!(geoData.continent in continentsMap)) continentsMap[geoData.continent] = [];
        continentsMap[geoData.continent].push(geoData);
    }
    let allGeoOptions = [];
    for (const continent in continentsMap) {
        allGeoOptions.push({
            label: continent,
            options: continentsMap[continent].map(geoData => ({
                value: geoData.geo, label: `${geoData.country} (${geoData.geo})`
            }))
        });
    }
    return allGeoOptions;
}

const getSelectedGeoOptions = (allGeo,selectedGeo) => {
    let geoMap = {};
    for (const geoData of allGeo) {
        geoMap[geoData.geo] = geoData;
    }
    let geoOptions = [];
    for (const geo of selectedGeo) {
        if (geo in geoMap) {
            const geoData = geoMap[geo];
            geoOptions.push({value: geoData.geo, label: `${geoData.country} (${geoData.geo})`});
        }
    }
    return geoOptions;
}


class CreateEditRoute extends Component {

    constructor(props){
        super(props);
        this.state = {
            name: '',
            loading: true,
            availableGames: [],
            availableDomains: [],
            allGeo: [],
            errors: {},
            postback_support: false,
            postback_uid_param: 's5',
            gameID: 0,
            appType: null,
            filterLevel: FilterLevel.LOW,
            whitePageType: WhitePageType.SAFE_PAGE,
            whitePageTheme: 'gaming',
            whitePageAppUrl: '',
            regenerateWhitePage: false,
            addNewDomain: false
            /*pushSupport: null*/
        };
    }

    componentDidMount() {
        let params = queryString.parse(this.props.location.search);
        if (this.props.location.pathname.startsWith('/editflow')) {
            let route_id = parseInt(params.id ? params.id : 0);
            if (route_id !== 0) {
                //console.log(`[QQQ] {componentDidUpdate} prevState.page:${prevState.page} newPage:${page}`);
                this.setState({editMode: true});
                this.fetchEditData(route_id);
            }
        } else {
            const appType = parseInt(params.at);
            const appsMode = !!params.apps || appType === StoreID.APP_STORE || appType === StoreID.PWA;
            this.setState({appType, appsMode});
            this.fetchCreateData(appType, appsMode);
        }
    }

    getUser() {
        return this.context;
    }

    async fetchCreateData(appType, appsMode) {
        const default_postback_uid_param = this.getUser().getFavData('default_postback_uid_param');
        const default_filter_level = this.getUser().getFavData('default_filter_level');
        const default_wp_type = this.getUser().getFavData('default_wp_type');
        const default_wp_theme = this.getUser().getFavData('default_wp_theme');

        let extra_state = (default_postback_uid_param && default_postback_uid_param !== '')
            ? { postback_support: true, postback_uid_param: default_postback_uid_param } : { postback_support: false };
        if (default_filter_level) extra_state.filterLevel =  default_filter_level;
        if (default_wp_type) extra_state.whitePageType =  default_wp_type;
        if (default_wp_theme) extra_state.whitePageTheme =  default_wp_theme;

        if (appsMode || appType === StoreID.UNIVERSAL) {
            this.setState({loading: true, ...extra_state});

            let [res,res2] = await axios.all([
                axios.post('/createroute_data', {appType}),
                /*axios.post('/get_extraChargeForPushSupport'),*/
            ]);

            if (res.data) {
                const prices = res.data.prices;
                const geoStateData = (Array.isArray(res.data.allGeo) && res.data.allGeo.length > 0) ? { allGeo: res.data.allGeo } : {};
                let domainsStateData = {};
                if (Array.isArray(res.data.domains) && res.data.domains.length > 0) {
                    domainsStateData.availableDomains = res.data.domains;
                    domainsStateData.newDomainID = parseInt(domainsStateData.availableDomains[0].id);
                }

                let newState = {};
                if (Array.isArray(res.data.games) && res.data.games.length > 0) {
                    let games = res.data.games;
                    let gameID = this.isUniversalLinkAvailable() ? 0 : parseInt(games[0].id);
                    const routeApps = games.length > 0 ? [{app_id: games[0].id, weight: 1.0}] : [];
                    newState = {availableGames: games, routeApps, initialRouteApps: routeApps,
                        ...this.prepareGameState(games,gameID)/* gameLink: this.getGameLink(games, gameID)*/};
                } else {
                    const routeApps =[{app_id: 0, weight: 1.0}];
                    newState = {availableGames: [], routeApps, initialRouteApps: routeApps};
                }
                this.setState({loading: false, ...newState, ...domainsStateData, ...geoStateData, ...prices,
                    landingTemplates: res.data.landing_templates});
            }
        } else {
            this.setState({loading: false, ...extra_state});
        }

        /*if (res2.data) {
            this.setState({extraChargeForPushSupport: res2.data.extraChargeForPushSupport, extraChargeForPushSupportDisc: res2.data.extraChargeForPushSupportDisc});
        }*/
    }

    isUniversalLinkAvailable = () => this.state.appType === StoreID.GOOGLE_PLAY; /*this.getUser().isNamingAvailable()*/

    async fetchEditData(route_id) {
        let res = await axios.post('/editroute_data', {route_id: route_id});

        if (res.data) {
            const games = res.data.games;
            const route = res.data.route;
            const allGeo = res.data.allGeo;
            const prices = res.data.prices;
            const geoStateData = (Array.isArray(allGeo) && allGeo.length > 0) ? { allGeo } : {};

            let domainsStateData = {};
            if (Array.isArray(res.data.domains) && res.data.domains.length > 0) {
                domainsStateData.availableDomains = res.data.domains;
                domainsStateData.newDomainID = parseInt(domainsStateData.availableDomains[0].id);
            }

            const routeApps = route.route_apps ? route.route_apps.map(g => {
                let {app_id, app_type, weight, os_type, target} = g;
                return {app_id: parseInt(app_id), app_type: parseInt(app_type), weight: parseFloat(weight), os_type: parseInt(os_type), target};
            }) : null;

            this.setState({...{
                loading: false,
                route_id: route_id,
                availableGames: games ? games : [{id: route.game_id, name: route.game_name}],
                name: route.name, targetLink: route.target_link,
                gameID: route.game_id,
                routeApps,
                initialRouteApps: routeApps,
                filterLevel: route.filter_level,
                whitePageType: route.white_page_params.whitePageType,
                whitePageTheme: route.white_page_params.whitePageTheme,
                whitePageAppUrl: route.white_page_params.whitePageAppUrl,
                postback_support: route.postback_support,
                postback_uid_param: route.postback_uid_param,
                appType: route.app_type,
                appsMode: route.app_type === StoreID.APP_STORE || route.app_type === StoreID.PWA,
                selectedGeo: getSelectedGeoOptions(allGeo,route.geo.split(',')),
                landingTemplates: res.data.landing_templates
            }, ...domainsStateData, ...geoStateData, ...prices});
        }
    }


    onSubmit(event) {
        event.preventDefault();

        if (this.busy) {
            console.log('busy');
            return;
        }

        /*if (this.state.pushSupport === null) {
            this.setState({errors: {pushSupport: "Пожалуйста выберите, нужны ли пуши?"}});
            return;
        }*/
        let {editMode,appsMode,appType,route_id,gameID,newDomainID,addNewDomain,selectedGeo,
            whitePageType,whitePageTheme,whitePageAppUrl,regenerateWhitePage,routeApps} = this.state;

        let params = pick(this.state,['name','targetLink','filterLevel',
            'postback_support','postback_uid_param'/*,'pushSupport'*/]);
        if (appType === StoreID.UNIVERSAL) {
            params.targetLink = 'distributed';
        } else {
            if (!params.targetLink) {
                this.setState({errors: {targetLink: 'Укажите целевую ссылку'}});
                return;
            }
            params.targetLink = params.targetLink.trim();
        }

        if (editMode) {
            params.route_id = route_id;
        }
        params.appType = appType;
        if (appType === StoreID.GOOGLE_PLAY) {
            if (!editMode) {
                params.game_id = gameID;
            }
        } else if (appType === StoreID.APP_STORE || appType === StoreID.PWA || appType === StoreID.UNIVERSAL) {
            if (appType === StoreID.APP_STORE || appType === StoreID.PWA) {
                params.route_apps = routeApps;
            } else {
                params.route_apps = [StoreID.APP_STORE, StoreID.GOOGLE_PLAY].reduce((rapps, osType) => (
                    [...rapps, ...this.state['distributions' + osType].map(d => ({...d, os_type: osType}))]
                ), []);
            }

            if (!editMode || addNewDomain) {
                params.new_domain_id = newDomainID;
            }
            params.geo = selectedGeo ? selectedGeo.map(g => g.value) : [];
            if (!editMode || regenerateWhitePage) {
                params.whitePageType = whitePageType;
                params.whitePageTheme = whitePageTheme;
                params.whitePageAppUrl = whitePageAppUrl;
            }
        }

        this.busy = true;
        axios.post(this.state.editMode ? '/updateroute' : '/newroute', params).then(res => {
            if (!editMode) {
                let default_postback_uid_param = params.postback_support ? params.postback_uid_param : '';
                this.getUser().setFavData('default_postback_uid_param', default_postback_uid_param);
                this.getUser().setFavData('default_filter_level', params.filterLevel);
                this.getUser().setFavData('default_wp_type', params.whitePageType);
                this.getUser().setFavData('default_wp_theme', params.whitePageTheme);
            }

            this.props.navigate('/');
            this.busy = false;
        }).catch((err) => {
            this.setState({errors: getResponseErrorsList(err)});
            this.busy = false;
        });
    }

    getError(param) {
        if (this.state.errors && param in this.state.errors) {
            return this.state.errors[param];
        }
        return '';
    }

    onFieldChanged(param) {
        return (e) => this.setState({ errors: '', [param]: e.target.value });
    }

    onPushSupportChange(e) {
        this.setState({ errors: '', pushSupport: e.target.value === "true" });
    }


    getGameLink(games, gameID) {
        if (games.length > 0) {
            const game = games.find(game => game.id===gameID);
            if (game) return game.link;
        }
        return '';
    }

    prepareGameState(games,gameID) {
        let game = games.find(game => game.id===gameID);
        return { gameID: gameID,
            gameExtraInfo: game ? game.extraInfo : '',
            /*gamePushSupport: game ? game.pushSupport : false,*/
            gameReleased: game ? game.released : 0,
            gameName: game ? game.name : '',
            gameDiscount: (game && game.priceRate !== 1.0) ? formatPercent(1.0 - game.priceRate) : ''};
    }

    onGameChanged() {
        return (e) => {
            let gameID = parseInt(e.target.value);
            this.setState({ ...{ errors: '', pushSupport: null}, ...this.prepareGameState(this.state.availableGames,gameID)} );
            /*, gameLink: this.getGameLink(this.state.availableGames, gameID) */
        }
    }

    onDomainChanged() {
        return (e) => {
            let newDomainID = parseInt(e.target.value);
            this.setState({ errors: '', newDomainID } );
        }
    }

    onPostbackParamSelected(e) {
        const val = e.target.value;
        this.setState({ postback_uid_param: val === OTHER_KEY ? '' : val });
    }

    onPostbackParamChanged(e) {
        this.setState({ postback_uid_param: e.target.value });
    }

    onGeoChanged(selectedGeo) {
        this.setState({ errors: '', selectedGeo });
    }

    onFilterLevelChange(e) {
        this.setState({ errors: '', filterLevel: parseInt(e.target.value) });
    }

    onWhitePageTypeChange(e) {
        this.setState({ errors: '', whitePageType: parseInt(e.target.value) });
    }

    onWhitePageThemeSelected(e) {
        this.setState({ errors: '', whitePageTheme: e.target.value });
    }

    onWhitePageAppUrlChanged(e) {
        this.setState({ errors: '', whitePageAppUrl: e.target.value });
    }

    onChangeAppWeights(routeApps) {
        this.setState({routeApps})
    };

    onChangeDistributons(osType, distributions) {
        this.setState({['distributions' + osType]: distributions});
    }

    render() {
        const renderError = (fieldName) => (
            <div className="error holder"><div className="message">{this.getError(fieldName)}</div></div>
        );

        let {loading,availableGames,availableDomains,targetLink,postback_support,
            postback_uid_param,editMode,appsMode,appType, gameReleased,gameExtraInfo,gameDiscount,
            selectedGeo,allGeo,filterLevel,whitePageType,whitePageTheme,whitePageAppUrl,regenerateWhitePage,
            addNewDomain,domain_use_price,regenerate_whitepage_price,routeApps,initialRouteApps,
            landingTemplates} = this.state;
        const checkPostbackSupport = () => { this.setState({postback_support: !postback_support}) };

        const otherPostbackParamSelected = !POSTBACK_PARAM_OPTIONS.some(v => v === postback_uid_param);

        const simpleColors = !this.getUser().hasPermission(UserGroup.MODERATOR);
        const allGeoOptions = getAllGeoOptions(allGeo);

        let filterLevelOptions = FilterLevel.allowedFilterLevels();
        if (appType === StoreID.APP_STORE || appType === StoreID.PWA) filterLevelOptions = filterLevelOptions.filter(f => f !== FilterLevel.OFF);
        const filterLevelLocalizedName = { 'Off': 'Откл.', 'Low': 'Низкий', 'Mid': 'Средний', 'High': 'Высокий' };

        const whitePageOptions = Object.keys(whitePageTypeDesc).map(wpt => parseInt(wpt));
        const checkRegenerateWhitePage = () => { this.setState({regenerateWhitePage: !regenerateWhitePage}) };
        const whitePageChangeDisabled = editMode && !regenerateWhitePage;

        const checkAddNewDomain = () => { this.setState({addNewDomain: !addNewDomain}) };
        const addNewDomainDisabled = editMode && !addNewDomain;

        return (
            <div className="page flows-form">
            <h1>{ editMode ? 'Изменить' : 'Создать'} поток</h1>
            <div className='center-section' style={{maxWidth: 1024, marginTop: 16}}>
            <div className='card'>
            <form className="wide" onSubmit={this.onSubmit.bind(this)} noValidate>
                <ul>
                    <li className="field">
                        <div><label htmlFor="name">Название потока</label></div>
                        <div className="tip">Необязательно, для вашего удобства</div>
                        <div><input id="name" name="name" type="text" value={this.state.name} onChange={this.onFieldChanged("name")} required/></div>
                        {renderError("name")}
                    </li>
                    {appType !== StoreID.UNIVERSAL &&
                    <li className="field">
                        <div><label htmlFor="targetLink">Целевая ссылка (target link)<span className="required"/></label></div>
                        <div className="tip">Укажите ссылку, куда должен перейти пользователь</div>
                        <div><Textarea id="targetLink" className="wide" name="targetLink"
                                       maxLength="512" value={targetLink} style={{height: '60px'}}
                                       onChange={this.onFieldChanged("targetLink")} required/></div>
                        <div className="tip"><p>Доступные макросы (опционально): <span style={{
                        }}>{'{_app_id_}'}</span> - ID приложения. <span style={{}}>{'{_dev_id_}'}</span> - уникальное ID устройства (для постбеков и т д).
                                При включенных постбеках он будет добавлятся сам. {
                            appType === StoreID.GOOGLE_PLAY && <span><span style={{}}>{'{_bund_id_}'}</span> - ID приложения в
                            маркете.  <span style={{/*fontWeight: 'bold'*/}}>{'{_camp_id_}'}</span> - ID кампании с апсфлаера (его партнерок), если инсталл прошел по неймингу</span>
                        }</p>
                        </div>
                        {renderError("targetLink")}
                    </li>
                    }
                    {/* РАСПРЕДЕЛЕНИЕ */ !loading && appType === StoreID.UNIVERSAL &&
                        [<li key='1' className="field">
                            <div><label>iOS поток</label></div>
                            <div style={{ padding: '2px' }}>
                                <TrafficDistribution
                                    appOptions={availableGames.filter(g => g.appType === StoreID.APP_STORE)}
                                    defaultDistribution={initialRouteApps ? initialRouteApps.filter(
                                        ra => ra.os_type === OsType.IOS) : null}
                                    landingTemplates={landingTemplates}
                                    onChange={this.onChangeDistributons.bind(this, StoreID.APP_STORE)}
                                />
                            </div>
                            {renderError("distribution" + StoreID.APP_STORE)}
                        </li>,
                        <li key='2' className="field">
                            <div><label>Android поток (+ другие платформы)</label></div>
                            <div style={{ padding: '2px' }}>
                                <TrafficDistribution
                                    appOptions={availableGames.filter(g => g.appType === StoreID.PWA)}
                                    defaultDistribution={initialRouteApps ? initialRouteApps.filter(
                                        ra => ra.os_type === OsType.ANDROID) : null}
                                    landingTemplates={landingTemplates}
                                    onChange={this.onChangeDistributons.bind(this, StoreID.GOOGLE_PLAY)}
                                />
                            </div>
                            {renderError("distribution" + StoreID.GOOGLE_PLAY)}
                        </li>]
                    }
                    {!loading && (appType === StoreID.APP_STORE || appType === StoreID.PWA || appType === StoreID.UNIVERSAL) &&
                        <li className="field">
                            { editMode &&
                                <div className="checkbox" onClick={checkAddNewDomain}>
                                    <input id="postback_support" type="checkbox" checked={addNewDomain} onChange={()=>{}}/>
                                    <label style={{marginLeft: 0}}>Добавить новый домен</label>
                                    <span className={addNewDomainDisabled ? 'disabled' : 'orange'}
                                          style={{margin: '1px 0 0 7px', fontSize: 14}}>(цена: <b>{formatCurrency(domain_use_price)}</b>)</span>
                                </div>
                            }
                            {!editMode && <div><label htmlFor="name">Домен</label></div>}
                            <div>
                                <select 
                                id="domain" 
                                name="domain" 
                                className="big-select"
                                onChange={this.onDomainChanged()} required
                                disabled={addNewDomainDisabled}>
                                    {(availableDomains && availableDomains.length>0) ? availableDomains.map(domain =>
                                        <option key={domain.id} value={domain.id}>{domain.name}</option>
                                    ) : <option key={0} value={0}>-- Нет свободных доменов --</option>}
                                </select>
                            </div>

                            {!editMode && <div className="orange" style={{fontSize: '12px', paddingLeft: '8px', display: 'block'}}>
                                Цена за использование нового домена: <b>{formatCurrency(domain_use_price)}</b></div>}
                            {renderError("domain")}
                        </li>
                    }
                    {(appType === StoreID.APP_STORE || appType === StoreID.PWA || appType === StoreID.UNIVERSAL) && [
                    <li key='1' className="field">
                        <div className="filtration-level-block">
                                <div style={{marginLeft: 0, marginRight: '2px'}}><label style={{display: 'flex', flexWrap: 'wrap'}}>Уровень фильтрации Adspect.io<span style={{fontSize:8, padding: '0 2px 8px 0'}}>TM</span>:</label></div>
                                <div className="radio-group">
                                    { filterLevelOptions.map( level =>
                                    <div key={level} className="radio">
                                        <label>
                                            <input 
                                            type="radio" 
                                            className='form-check-input' 
                                            value={level} 
                                            checked={level === filterLevel}
                                            onChange={this.onFilterLevelChange.bind(this)} />
                                                {filterLevelLocalizedName[FilterLevel.toString(level)]}
                                        </label>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="tip" style={{minHeight: 39}}>{filterLevel in adspectFilterDesc ? adspectFilterDesc[filterLevel] : ''}</div>
                        {renderError("filterLevel")}
                    </li>,
                    <li key='2' className="field">
                        <div><label>Открытые гео</label></div>
                        <Select className='select' isMulti value={selectedGeo} onChange={this.onGeoChanged.bind(this)}
                                options={allGeoOptions} placeholder="Выбрать..."/>
                        {renderError("geo")}
                    </li>]}
                    {/* ПРИЛОЖЕНИЯ */ !loading && appsMode &&
                    <li className="field">
                        <div><label>Приложение</label></div>
                        {(appType === StoreID.APP_STORE || appType === StoreID.PWA) ?
                        <div style={{ padding: '2px' }}>
                            <AppTrafficDistribution
                                appOptions={availableGames}
                                defaultDistribution={initialRouteApps}
                                onChange={this.onChangeAppWeights.bind(this)}
                            />
                        </div>
                        :
                        <div><select id="game" name="game" onChange={this.onGameChanged()} required disabled={this.state.editMode}
                                     className={GameReleaseState.getColorClass(gameReleased, "", simpleColors)}>
                            {/*[UNIVERSAL]*/ this.isUniversalLinkAvailable() &&
                                <option key={0} value={0} className={"text-default"}>Универсальный линк (any app)</option>
                            }
                            {availableGames.map(game => {
                                return <option key={game.id} value={game.id}
                                               className={GameReleaseState.getColorClass(game.released, "text-default", simpleColors)}>{game.name}</option>
                            })}
                        </select>
                        </div>}
                        { appType === StoreID.GOOGLE_PLAY && gameReleased === GameReleaseState.BANNED_BY_FB &&
                            <div style={{fontSize: '14px', fontWeight: 'bold', paddingLeft: '8px', display: 'block'}} className={'gray'}>Прилка забанена в FB</div>
                        }
                        { appType === StoreID.GOOGLE_PLAY && gameReleased === GameReleaseState.RESTRICTED_99_CABS &&
                            <div className="orange" style={{fontSize: '12px', paddingLeft: '8px', display: 'block'}}>Прилка имеет ограничение 99 по кабам в FB</div>
                        }
                        { appType === StoreID.GOOGLE_PLAY && gameExtraInfo &&
                            <div className="orange" style={{fontSize: '12px', paddingLeft: '8px', display: 'block'}}>{gameExtraInfo}</div>
                        }
                        { appType === StoreID.GOOGLE_PLAY && gameDiscount &&
                            <div style={{fontSize: '12px', paddingLeft: '8px', color: '#328f42', display: 'block'}}>
                                Приложение со скидкой за инсталлы: <b>-{gameDiscount}</b></div>
                        }
                        {/*this.state.gameLink &&
                            <div style={{'font-size': '12px', 'padding-left': '8px'}}><a href={this.state.gameLink} target="_blank" rel="noopener noreferrer">{this.state.gameLink}</a></div>
                        */}
                    {renderError("game")}
                    </li>}
                    {(appType === StoreID.APP_STORE || appType === StoreID.PWA || appType === StoreID.UNIVERSAL) &&
                    <li className="field">
                        { editMode &&
                        <div className="checkbox" onClick={checkRegenerateWhitePage}>
                            <input id="postback_support" type="checkbox" checked={regenerateWhitePage} onChange={()=>{}}/>
                            <label style={{marginLeft: 0}}>Перегенерировать Whitepage</label>
                                <span className={whitePageChangeDisabled ? 'disabled' : 'orange'}
                                      style={{margin: '1px 0 0 7px', fontSize: 14}}>(цена: <b>{formatCurrency(regenerate_whitepage_price)}</b>)</span>
                        </div>}
                        <div className="filtration-level-block">
                            <div className={(whitePageChangeDisabled ? 'disabled' : '')} style={{marginLeft: 0, marginRight: '2px'}}>
                                <label>Whitepage:</label>
                            </div>
                            <div className='radio-group'> 
                                { whitePageOptions.map( wpt =>
                                    <div key={wpt} className={"radio" + (whitePageChangeDisabled ? ' disabled' : '')}>
                                        <label>
                                            <input 
                                            type="radio" 
                                            className='form-check-input' 
                                            value={wpt} 
                                            checked={wpt === whitePageType}
                                            onChange={this.onWhitePageTypeChange.bind(this)}
                                            disabled={whitePageChangeDisabled}/>
                                                {whitePageTypeDesc[wpt].name}
                                        </label>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='whitepage-options'>
                                        <div style={{marginLeft: 0, marginRight: '2px'}}>
                                            <label className="smallerText" style={{marginLeft: 0}}>{whitePageType in whitePageTypeDesc ?
                                                whitePageTypeDesc[whitePageType].urlDesc || whitePageTypeDesc[whitePageType].desc: ''}
                                            </label>
                                        </div>
                                        <div style={{margin: '2px 0'}}>
                                            {whitePageType in whitePageTypeDesc && whitePageTypeDesc[whitePageType].isTheme ?
                                            <div className="inRow leftAlign">
                                            <label className="smallerText" style={{margin: '0'}}>Тема:</label>
                                                <select
                                                 id="whitePageTheme" 
                                                name="whitePageTheme"
                                                className="select smallerText"
                                                disabled={whitePageChangeDisabled}
                                                value={whitePageTheme} onChange={this.onWhitePageThemeSelected.bind(this)} required>
                                                    {whitePageThemes.map(theme => <option key={theme} value={theme}>{theme}</option>)}
                                                </select>
                                            </div>
                                            :
                                            <input style={{width: '100%', padding: '6px'}} value={whitePageAppUrl}
                                                disabled={whitePageChangeDisabled}
                                                onChange={this.onWhitePageAppUrlChanged.bind(this)}/>
                                            }
                                        </div>
                        </div>
                        {renderError("whitepage")}
                    </li>}
                    <li className="field" style={{justifyContent: 'start'}}>
                        <div className="inRow leftAlign">
                            <label style={{marginLeft: 0}}>Оптимизация:</label>
                            <div className="checkbox" onClick={checkPostbackSupport}>
                                <input id="postback_support" className='form-check-input' type="checkbox" checked={postback_support} onChange={()=>{}}/>
                                <label style={{marginLeft: 0}}>Включить постбеки</label>
                            </div>
                        </div>
                        {postback_support ?
                            <div style={{margin: '0 0 0 0'}}>
                                <div className='postback-block'>
                                    <label className="smallerText">Параметр передачи ID устройства в трекер:</label>
                                    <div>
                                        <select 
                                        className="select smallerText" value={otherPostbackParamSelected ? OTHER_KEY : postback_uid_param}
                                        onChange={this.onPostbackParamSelected.bind(this)}>
                                            {POSTBACK_PARAM_OPTIONS.map(v => <option key={v} value={v}>{v}</option> )}
                                            <option key={OTHER_KEY} value={OTHER_KEY}>другой:</option>
                                        </select>
                                        {otherPostbackParamSelected &&
                                            <input className='' style={{padding: 6, width: '100%'}} value={postback_uid_param} onChange={this.onPostbackParamChanged.bind(this)}/>}
                                    </div>
                                </div>

                                        <div className="leftAlign">
                                            <label className="tip" style={{marginLeft: '6px'}}>(не используйте его для других целей, и убедитесь что он правильно принимается трекером/партнеркой)</label>
                                        </div>

                                        <div className="inRow leftAlign" style={{marginTop: '6px'}}>
                                            <label className="smallerText" >Активируйте один или несколько постбеков в трекере (или партнерке):</label>
                                        </div>
                                        <div className='smallTable' style={{ margin: "10px 6px 14px"}}>
                                            {FB_EVENTS.map(e => {
                                                const link = POSTBACK_LINK.replace('{event}', e.fbevent).replace('{uid}', `{${postback_uid_param}}`);
                                                return (<div key={e.fbevent}>
                                                    <div className='header' style={{width: '150px'}}>{e.name}</div>
                                                    <div className="itemFill inRow leftAlign" style={{padding: '3px', width: 30}}>
                                                        <CopyToClipboard text={link}>
                                                            <button type="button" className="copy-btn" data-tip="Скопировать" data-for={'tooltip_' + e.fbevent}><CopyIcon/></button>
                                                        </CopyToClipboard>
                                                        <div style={{marginLeft: '0px'}} className='nowrap'>{link}</div>
                                                    </div>
                                                </div>);
                                            })}
                                        </div>
                                        {renderError("postback_uid_param")}
                                        {FB_EVENTS.map(e =>
                                            <div key={e.fbevent}>
                                                <ReactTooltip id={'tooltip_'+e.fbevent} delayShow={100} type="light" effect="solid" />
                                            </div>
                                        )}
                                    </div>
                                    : null}
                    </li>
                    <li className="submit inCol">
                        <div className="submit inCol">
                            {this.state.editMode &&
                            <div className='warning-msg' style={{marginBottom: '10px', fontStyle: 'italic'}}>Будьте внимательны при изменении параметров в активных кампаниях.
                                <br/>Изменения вступят в силу в течение 5 минут</div>
                            }
                            <div><Button type='submit' withoutRounded title={editMode ? 'Изменить' : 'Создать'}/></div>
                        </div>
                    </li>
                    {renderError("common")}
                </ul>
            </form>
            </div>
            </div>
            <div className="footer" style={{ marginTop: 20}}>
                <div/>
                <Link to="/" ><Button title='Назад' withoutRounded variant='warning'/></Link>
                <div/>
            </div>
        </div>
    );
}
}
CreateEditRoute.contextType = UserContext;

export default props => <CreateEditRoute location={useLocation()} navigate={useNavigate()} {...props}/>;
