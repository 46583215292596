import LanguagesTabs from "../../components/UI/LanguagesTabs";
import ModalWindow from "../../widgets/ModalWindow";
import AddLanguageForm from "./AddLanguageForm";
import { MAX_LANGUAGES } from "../../constants/pwa";

const LanguagesList = ({
    addLanguage,
    data,
    activeLng,
    setActiveLng,
    deleteLng,
    isShowModal,
    setIsShowModal,
    languagesList,
    isDescFlow,
    templatesDesc,
    isLoading
}) => {
    const showLanguagesModal = () => setIsShowModal(true);
    const closeLanguagesModal = () => setIsShowModal(false);

    return (
        <>
            <ModalWindow
                isVisible={isShowModal}
                onClose={closeLanguagesModal}
                anchorContainerID="create-edit-pwa"
                closeButton
            >
                {isShowModal && (
                    <AddLanguageForm
                        currentLanguage={data}
                        onSubmit={addLanguage}
                        onBack={closeLanguagesModal}
                        languagesList={languagesList}
                        withAutoGenerateDesc
                        isDescFlow={isDescFlow}
                        templatesDesc={templatesDesc}
                        isLoading={isLoading}
                    />
                )}
            </ModalWindow>

            <LanguagesTabs
                addLanguage={showLanguagesModal}
                activeLng={activeLng}
                setActiveLng={setActiveLng}
                deleteLng={deleteLng}
                items={data}
                languagesList={languagesList}
                isDisabledAddButton={
                    data && Object.keys(data).length >= MAX_LANGUAGES
                }
            />
        </>
    );
};

export default LanguagesList;
