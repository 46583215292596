import React, { useEffect } from "react";
import "./alert.css";
import SuccessIcon from "../icons/SuccessIcon";
import ErrorIcon from "../icons/ErrorIcon";

const Alert = ({ variant, message, isVisible, onClose }) => {
    useEffect(() => {
        if (isVisible) {
            const timer = setTimeout(() => {
                onClose();
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [isVisible, onClose]);

    const getAlertClassName = () => {
        let className = "alert";
        if (variant === "success") className += " alert--success";
        if (variant === "error") className += " alert--error";
        if (!isVisible) className += " alert--hidden";
        return className;
    };

    return (
        <div className={getAlertClassName()} onClick={onClose}>
            <div className="alert__icon">
                {variant === "success" && <SuccessIcon />}
                {variant === "error" && <ErrorIcon />}
            </div>
            <div className="alert__message">{message}</div>
        </div>
    );
};

export default Alert;
