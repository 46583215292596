import React, { useState, useEffect, useId } from 'react';
import Select from 'react-select';
import { Tooltip as ReactTooltip } from "react-tooltip";
import {GameReleaseState, StoreID} from "../../common/commonTypes";
import Button from '../../components/UI/Button';
import CloseIcon from '../../components/icons/CloseIcon'
import Textarea from '../../components/UI/Textarea';
import PlanetIcon from '../../components/icons/PlanetIcon';
import LandingModal from './LandingModal';
import LandingConfigurator from './LandingConfigurator';

const UNIVERSAL_REDIRECT_APP_ID = 1;

const DISTRIBUTION_TYPES = {
    [StoreID.UNIVERSAL]: 'Внешний линк/приложение',
    [StoreID.GOOGLE_PLAY]: 'Приложение Android',
    [StoreID.APP_STORE]: 'Приложение iOS',
    [StoreID.PWA]: 'PWA',
};

const SLIDER_STEP_COUNT = 10000;
const MIN_STEP = 1 / SLIDER_STEP_COUNT;
const EPSILON = 1e-9;

const roundWeigth = w => Math.round(w * SLIDER_STEP_COUNT) / SLIDER_STEP_COUNT;
const clampWeight = w => {
    if (w < EPSILON) w = 0.0;
    if (w > 1.0 - EPSILON) w = 1.0;
    return w;
};

const TrafficDistribution = ({ appOptions, defaultDistribution, onChange, landingTemplates }) => {
    const [distributions, setDistributions] = useState([]);
    const [distributionTypes, setDistributionTypes] = useState([]);
    const [appsByType, setAppsByType] = useState([]);
    const id = useId();

    const initialDistribution = (distributionTypes, appsByType) => ({
        app_id: appsByType[distributionTypes[0]][0].id,app_type: distributionTypes[0], weight: 1.0, targetLink: ''
    });

    useEffect(() => {
        const distributionTypes = [
            ...new Set(appOptions.map(app => app.appType)),
            StoreID.UNIVERSAL
        ];
        const appsByType = {
            [StoreID.UNIVERSAL]: [{id: UNIVERSAL_REDIRECT_APP_ID}],
            ...appOptions.reduce((apps, app) => {
                if (! (app.appType in apps)) apps[app.appType] = [];
                apps[app.appType].push(app);
                return apps;
            }, {})
        };
        setDistributionTypes(distributionTypes);
        setAppsByType(appsByType);
        if (distributions.length === 0) {
            if (!defaultDistribution || defaultDistribution.length === 0) {
                setDistributions([initialDistribution(distributionTypes,appsByType)]);
            }
        }
    }, [appOptions]);

    useEffect(() => {
        if (distributions.length === 0) {
            if (defaultDistribution && defaultDistribution.length>0) {
                setDistributions(defaultDistribution.map((dist) => ({
                    app_id: dist.app_id ? dist.app_id : UNIVERSAL_REDIRECT_APP_ID,
                    app_type: dist.app_type ? dist.app_type : StoreID.UNIVERSAL,
                    weight: roundWeigth(dist.weight),
                    ...parseTarget(dist.target)
                })));
            }
        }
    }, [appOptions, defaultDistribution]);

    const updateDistribution = (index, changeCallback, shouldRecalculateWeights) => {
        let newDistributions = [...distributions];
        changeCallback(newDistributions[index]);
        if (shouldRecalculateWeights) newDistributions = recalculateWeights(newDistributions, index);
        setDistributions(newDistributions);
    }

    const handleDistTypeChange = (value, index) => updateDistribution(index, dist => {
        const prevDistType = dist.app_type;
        dist.app_type = value;
        if (prevDistType !== value) {
            dist.app_id = appsByType[dist.app_type].length > 0 ? appsByType[dist.app_type][0].id : 0;
        }
    });
    const handleWeightChange = (value, index) => updateDistribution(index, dist => {
        dist.weight = clampWeight(roundWeigth(value));
    }, true);
    const handleIsLandingChange = (value, index) => updateDistribution(index, dist => {
        dist.isLanding = value;
        if (!dist.landing && landingTemplates && landingTemplates.length > 0) dist.landing = landingTemplates[0];
    });
    const handleIsInternalLandingChange = (value, index) => updateDistribution(index, dist => {
        dist.isInternalLanding = value;
        if (!dist.internalLanding && landingTemplates && landingTemplates.length > 0) dist.internalLanding = landingTemplates[0];
    });
    const handleParamChange = (value, index, param) => updateDistribution(index, dist => {
        dist[param] = value;
    });
    const handleAppChange = (value, index) => updateDistribution(index, dist => {
        dist.app_id = value.id;
    });


    const recalculateWeights = (distributions, changedIndex) => {
        const totalWeight = distributions.reduce((sum, dist) => sum + dist.weight, 0);

        if (totalWeight === 1) {
            return distributions;
        }

        if (distributions.length === 1 && changedIndex >= 0) {
            distributions[changedIndex].weight = 1;
            return distributions;
        }

        const remainingWeight = 1 - distributions.reduce((sum, dist) => sum + dist.weight, 0);
        const changeDirection = Math.sign(remainingWeight);
        const canChangeNextDists = distributions.reduce((_canChange, dist, i) => (i > changedIndex &&
            (_canChange || changeDirection < 0 && dist.weight > EPSILON || changeDirection > 0 && dist.weight < (1.0 - EPSILON))), false);

        let canChange;
        if (canChangeNextDists)
            // ми можемо автоматом крутити наступні повзунки після поточного, не збиваючи попередні значення
            canChange = (index) => index > changedIndex || (changedIndex === distributions.length-1) && index !== changedIndex;
        else
            // в цьому напрямку нема можливості крутити наступні, і доведеться автоматом просто крутити всі інші
            canChange = (index) => index !== changedIndex;

        const lockedWeights = distributions.filter((_, i) => !canChange(i));
        const desireRemainingWeight = lockedWeights.reduce((sum, dist) => sum - dist.weight, 1);
        const otherWeights = distributions.filter((_, i) => canChange(i));
        const otherWeightsSum = otherWeights.reduce((sum, dist) => sum + dist.weight, 0);

        const finalGrooming = (distributions) => {
            let remainingWeight = 1 - distributions.reduce((sum, dist) => sum + dist.weight, 0);
            if ( remainingWeight > -EPSILON && remainingWeight < EPSILON ) {
                return distributions;
            }
            const sign = Math.sign(remainingWeight);
            return distributions.map((dist, i) => {
                if (canChange(i) && ( sign > 0 && remainingWeight > (MIN_STEP - EPSILON)
                    || sign < 0 && remainingWeight < (-MIN_STEP + EPSILON) ))
                {
                    let newWeight = clampWeight(roundWeigth(dist.weight + sign * MIN_STEP));
                    remainingWeight = remainingWeight - (newWeight - dist.weight);
                    return {...dist, weight: newWeight};
                } else {
                    return dist;
                }
            });
        }

        if (otherWeightsSum === 0) {
            return finalGrooming(distributions.map((dist, i) =>
                canChange(i) ? { ...dist, weight: clampWeight(roundWeigth(desireRemainingWeight / otherWeights.length)) } : dist
            ));
        }

        return finalGrooming(distributions.map((dist, i) =>
            canChange(i) ? { ...dist, weight: clampWeight(roundWeigth((dist.weight / otherWeightsSum) * desireRemainingWeight)) } : dist
        ));
    };

    const handleAddDist = () => {
        setDistributions([...distributions, { ...initialDistribution(distributionTypes,appsByType),
            weight: distributions.length === 0 ? 1 : 0 }]);
    };

    const handleRemoveDist = (index) => {
        const newDistributions = recalculateWeights(distributions.filter((_, i) => i !== index), -1);
        setDistributions(newDistributions);
    };

    useEffect(() => {
        onChange(
            distributions
                .filter((dist) => dist.app_id !== null)
                .map((dist) => ({
                    app_id: dist.app_id,
                    weight: dist.weight,
                    target: composeTarget(dist)
                }))
        );
    }, [distributions]);

    const parseTarget = (target) => {
        let targetLink = '';
        let landing = '';
        let internalLanding = '';
        let landingFields = {};
        let internalLandingFields = {};
        if (target) {
            try {
                const targetData = JSON.parse(target);
                targetLink = targetData.link;
                if (targetData.landing) {
                    landing = targetData.landing
                }
                if (targetData.internalLanding) {
                    internalLanding = targetData.internalLanding
                }
                if(targetData.landingFields) {
                    landingFields = targetData.landingFields
                }
                if(targetData.internalLandingFields) {
                    internalLandingFields = targetData.internalLandingFields
                }
            } catch (e) {
                targetLink = target;
            }
        }
        return {isLanding: !!landing, landing, isInternalLanding: !!internalLanding, internalLanding, targetLink, landingFields, internalLandingFields};
    }

    const isLanding = (dist) => (dist.isLanding && dist.landing
        && dist.app_type !== StoreID.APP_STORE);
    const isInternalLanding = (dist) => (dist.isInternalLanding && dist.internalLanding
        && dist.app_type !== StoreID.UNIVERSAL && dist.app_type !== StoreID.APP_STORE);
    const isFieldsSet = (fields) => fields && Object.keys(fields)?.length > 0;

    const composeTarget = (dist) => JSON.stringify({
        landing: isLanding(dist) ? dist.landing : '',
        ...((isLanding(dist) && isFieldsSet(dist.landingFields)) ? {landingFields: dist.landingFields} : {}),
        internalLanding: isInternalLanding(dist) ? dist.internalLanding : '',
        ...(isInternalLanding(dist) && isFieldsSet(dist.internalLandingFields) ? {internalLandingFields: dist.internalLandingFields} : {}),
        link: dist.targetLink
    });

    return (
        <div className='traffic-distribution'>
            {/*<h3>Total : {distributions.reduce((sum, dist) => sum + dist.weight, 0)}</h3>*/}
            {distributions.map((dist, index) =>
                <div className={`traffic-distribution-item  ${distributions.length > 1 ? 'with-range' : ''}`} key={index}>
                    <div className={'traffic-distribution-item-content' + (distributions.length > 1 ? ' extended' : '')}>
                        <div className='sub-block' style={{minHeight: '38px'}}>
                            <div className='sub-block-2' style={{width: '100%'}}>
                                <div>
                                    <select required value={dist.app_type}
                                            onChange={(e) => handleDistTypeChange(parseInt(e.target.value), index)}>
                                        {distributionTypes.map((distType) =>
                                            <option key={distType} value={distType}>{DISTRIBUTION_TYPES[distType]}</option>
                                        )}
                                    </select>
                                </div>
                                {dist.app_type !== StoreID.APP_STORE && 
                                    <LandingConfigurator 
                                      label="Прелендинг"
                                      isActive={dist.isLanding}
                                      landing={dist.landing}
                                      landingFields={dist.landingFields}
                                      landingTemplates={landingTemplates}
                                      handleIsLandingChange={(val) => handleIsLandingChange(val, index)}
                                      handleLandingChange={(val) => handleParamChange(val, index, "landing")}
                                      handleParamChange={(val) => handleParamChange(val, index, "landingFields")}
                                    />
                                }
                                {dist.app_type !== StoreID.UNIVERSAL &&
                                <div style={{minWidth: '220px'}}>
                                    <label>Приложение:</label><div style={{ width: '170px' }}>
                                    <Select className='select traffic-distribution-select'
                                            value={dist.app_id ? { value: dist.app_id, label:
                                                appsByType[dist.app_type]?.find(app => app.id === dist.app_id)?.name
                                            } : null}
                                            onChange={selectedOption => handleAppChange(selectedOption.value, index)}
                                            options={appsByType[dist.app_type].map((app) => ({ value: app, label: app.name }))}
                                            placeholder="Выберите приложение"
                                    /></div>
                                </div>}
                                {dist.app_type !== StoreID.UNIVERSAL && dist.app_type !== StoreID.APP_STORE && 
                                    <LandingConfigurator 
                                        label="Внутр. лендинг"
                                        isActive={dist.isInternalLanding}
                                        landing={dist.internalLanding}
                                        landingFields={dist.internalLandingFields}
                                        landingTemplates={landingTemplates}
                                        handleIsLandingChange={(val) => handleIsInternalLandingChange(val, index)}
                                        handleLandingChange={(val) => handleParamChange(val, index, "internalLanding")}
                                        handleParamChange={(val) => handleParamChange(val, index, "internalLandingFields")}
                                    />
                                }
                            </div>
                        </div>
                        <div className='sub-block'>
                            <div className='target-link-container'>
                                <div className="tip" style={{padding: '0 0 1px 4px'}}>Целевая ссылка (target link)</div>
                                <div className="tip"></div>
                                <Textarea
                                    style={{resize: "vertical", height: '60px'}}
                                    id="targetLink" className="wide"
                                    name="targetLink"
                                    maxLength="512"
                                    value={dist.targetLink}
                                    onChange={e => handleParamChange(e.target.value, index, 'targetLink')} required/>
                            </div>
                            {distributions.length > 1 && <div className='range-block'>
                                <input
                                    type="range"
                                    className='form-range'
                                    value={dist.weight * 1000}
                                    onChange={(e) => handleWeightChange(Number(e.target.value) / 1000, index)}
                                    min="0"
                                    max={1000}
                                    step={10}
                                />
                                <div className='' style={{whiteSpace: "nowrap", marginLeft: '3px'}}><input
                                    type="number"
                                    value={Math.round(dist.weight * 10000) / 100}
                                    onChange={(e) => handleWeightChange(Number(e.target.value) / 100, index)}
                                    min={0}
                                    max={100}
                                />%</div>
                                <button
                                    type="button"
                                    onClick={() => handleRemoveDist(index)}
                                >
                                    <CloseIcon/>
                                </button>
                            </div>
                            }
                        </div>
                    </div>
                    <ReactTooltip id={"landing" + index} type="light" effect="solid" />
                    <ReactTooltip id={"internal-landing" + index} type="light" effect="solid" />
                </div>
            )}
            <Button variant='warning' type='button' onClick={handleAddDist} style={{marginLeft: '24px'}} withoutRounded title='Добавить распределение' size='small' />
        </div>
    );
};

export default TrafficDistribution;
