import { memo, useState } from "react";
import ModalWindow from "../../widgets/ModalWindow";
import SeeIcon from "../icons/SeeIcon";
import CloseIcon from "../icons/CloseIcon";

const ImageWithModal = ({ src, onDelete }) => {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const openModal = () => setIsOpenModal(true);
    const closeModal = () => setIsOpenModal(false);
    return (
        <>
            <ModalWindow
                isVisible={isOpenModal}
                onClose={closeModal}
                anchorContainerID="create-edit-pwa"
                closeButton
                className="image-modal"
            >
                <img src={src} width="100%" />
            </ModalWindow>
            <div className="img-block">
                <div
                    style={{
                        backgroundImage: `url('${src}')`,
                    }}
                />
                <button className="seeBtn" onClick={openModal}>
                    <SeeIcon />
                </button>
                <button type="button" className="closeBtn" onClick={onDelete}>
                    <CloseIcon />
                </button>
            </div>
        </>
    );
};

export default memo(ImageWithModal, (prevProps, nextProps) => {
    return prevProps === nextProps;
});
