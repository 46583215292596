import React, { useContext } from "react";
import { StoreID, UserGroup } from "../../common/commonTypes";
import AndroidIcon from "../../components/icons/AndroidIcon";
import AppleIcon from "../../components/icons/AppleIcon";
import { UserContext } from "../../context/User";

const buttons = [
    { appType: StoreID.UNIVERSAL, name: "Universal", icon: "" },
    { appType: StoreID.PWA, name: "PWA", icon: "" },
    { appType: StoreID.APP_STORE, name: "iOS", icon: <AppleIcon /> },
    { appType: StoreID.GOOGLE_PLAY, name: "Android", icon: <AndroidIcon /> },
];

const MenuAppTypeItem = ({ appType, name, icon, isActive, onClick }) => (
    <button className={isActive ? "active" : ""} onClick={onClick}>
        {icon}
        {name}
    </button>
);

const MenuAppType = ({ activeAppType, onSelect, isAppsPage }) => {
    const user = useContext(UserContext);
    return (
        <div className="tab-buttons-wrapper">
            <div className="tab-buttons">
                {buttons
                    .filter((b) => !isAppsPage && b.appType !== StoreID.PWA
                        || isAppsPage && b.appType !== StoreID.UNIVERSAL)
                    .map((b) => (
                        <MenuAppTypeItem
                            appType={b.appType}
                            key={b.name}
                            name={b.name}
                            icon={b.icon}
                            isActive={b.appType === activeAppType}
                            onClick={onSelect.bind(null, b.appType)}
                        />
                    ))}
            </div>
        </div>
    );
};

export default MenuAppType;
