import ModalWindow from "../../widgets/ModalWindow";
import Button from "./Button";

const WarningModal = ({
    isVisible,
    anchorContainerID,
    onClose,
    text,
    onConfirm,
    isLoading,
    confirmText = "Сохранить",
    closeText = "Нет",
    confirmBtnVariant = "success",
    closeBtnVariant = "error",
}) => (
    <ModalWindow
        isVisible={isVisible}
        anchorContainerID={anchorContainerID}
        onClose={onClose}
        closeButton
        className="warning-modal"
    >
        <div className="warning-modal-content">
            <h2 dangerouslySetInnerHTML={{ __html: text }} />
            <div className="buttons-block">
                <Button
                    withoutRounded
                    title={closeText}
                    onClick={onClose}
                    variant={closeBtnVariant}
                />
                <Button
                    withoutRounded
                    title={confirmText}
                    onClick={onConfirm}
                    variant={confirmBtnVariant}
                    isLoading={isLoading}
                />
            </div>
        </div>
    </ModalWindow>
);

export default WarningModal;
