import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import {
    AGE_OPTIONS,
    DATE_SELECT_OPTIONS,
    DISALLOWED_NUMBER_SYMBOLS,
    INIT_DESC_PWA,
    TAGS_PWA,
    WINDOW_INSTALL_OPTIONS,
} from "../../constants/pwa";
import ImageUploader from "../../components/UI/ImageUploader";
import ScreensBlock from "./ScreensBlock";
import TextareaWithLabel from "../../components/UI/TextareaWithLabel";
import InputWithLabel from "../../components/UI/InputWithLabel";
import SelectWithLabel from "../../components/UI/SelectWithLabel";
import { formatRating, sortLanguages } from "../../util/util";
import LanguagesList from "./LanguagesList";
import Accordion from "../../components/UI/Accordion";
import { ImageType } from "../../common/commonTypes";
import ImageWithModal from "../../components/UI/ImageWithModal";
import CreatableSelectWithLabel from "../../components/UI/CreatableSelectWithLabel";

const Description = ({
    data,
    updateData,
    languagesList,
    templatesDesc,
    createErrorMessage,
}) => {
    const [isShowLanguagesModal, setIsShowLanguageModal] = useState(false);
    const [isLoadingLangModal, setIsLoadingLangModal] = useState(false);
    const [activeLng, setActiveLng] = useState(
        data?.descriptions ? Object.keys(data.descriptions)[0] : ""
    );

    const addLanguage = async ({
        lngFrom,
        lngTo,
        isAutoGenerateDesc,
        template,
    }) => {
        let langData = { ...INIT_DESC_PWA };
        let defaultLangData = {};
        let autoLandData = {};

        if (lngFrom) {
            langData = { ...data.descriptions[lngFrom] };
        } else {
            if (data.defaultLang) {
                defaultLangData = { ...data.descriptions[data.defaultLang] };
            } else if (
                data.descriptions &&
                Object.values(data.descriptions).length
            ) {
                defaultLangData = { ...Object.values(data.descriptions)[0] };
            }

            delete defaultLangData.desc;
            delete defaultLangData.news;
            delete defaultLangData.windowInstallType;
            delete defaultLangData.windowInstallDesc;

            if (isAutoGenerateDesc) {
                setIsLoadingLangModal(true);
                try {
                    const { data } = await axios.post("/pwa_auto_description", {
                        lang: lngTo,
                        template,
                    });

                    autoLandData = { ...data };
                } catch (err) {
                    createErrorMessage(err);
                }
            }
        }

        langData = { ...langData, ...defaultLangData, ...autoLandData };
        setIsLoadingLangModal(false);
        updateData({ keys: ["descriptions", lngTo], value: langData });
        setIsShowLanguageModal(false);
        setActiveLng(lngTo);
    };
    const deleteLng = (lng) => {
        updateData({
            keys: ["descriptions", lng],
            type: "delete",
            isNotUpdateLastUpdatedTime: true,
        });

        if (lng === data.defaultLang) {
            updateData({ keys: ["defaultLang"], value: "" });
        }
    };

    const updateLngContent = (key, value, isNotUpdateLastUpdatedTime) => {
        updateData({
            keys: ["descriptions", activeLng, key],
            value,
            isNotUpdateLastUpdatedTime,
        });
    };

    const updateDesc = (key, value) => {
        updateData({ keys: [key], value });
    };

    const defaultLanguages = useMemo(() => {
        const languages = [];

        if (!languagesList) return languages;

        Object.keys(languagesList).forEach((lng) => {
            if (data?.descriptions?.[lng]) {
                languages.push({
                    label: languagesList[lng],
                    value: lng,
                });
            }
        });

        return sortLanguages(languages);
    }, [data?.descriptions, languagesList]);

    const content = data?.descriptions?.[activeLng];

    useEffect(() => {
        if (
            activeLng &&
            data &&
            data.descriptions &&
            !data.descriptions[activeLng] &&
            Object.keys(data.descriptions).length
        ) {
            setActiveLng(Object.keys(data.descriptions)[0]);
        }
    }, [data, activeLng]);

    return (
        <div className="description-pwa" id="description-pwa">
            {data.descriptions && Object.keys(data.descriptions).length ? (
                <div className="four-fields" style={{ margin: "0 0 16px" }}>
                    <SelectWithLabel
                        label="Язык по умолчанию"
                        options={defaultLanguages}
                        value={data.defaultLang}
                        fieldName="defaultLang"
                        onChange={updateDesc}
                    />
                </div>
            ) : (
                ""
            )}
            <div>
                <LanguagesList
                    data={data?.descriptions}
                    addLanguage={addLanguage}
                    deleteLng={deleteLng}
                    activeLng={activeLng}
                    setActiveLng={setActiveLng}
                    isShowModal={isShowLanguagesModal}
                    setIsShowModal={setIsShowLanguageModal}
                    languagesList={languagesList}
                    templatesDesc={templatesDesc}
                    isDescFlow
                    isLoading={isLoadingLangModal}
                />
            </div>

            {content && (
                <div className="desc-lang">
                    <div className="four-fields">
                        <InputWithLabel
                            label="Название PWA"
                            value={content.title}
                            fieldName="title"
                            onChange={updateLngContent}
                        />
                        <InputWithLabel
                            label="Имя разработчика"
                            value={content.developer}
                            fieldName="developer"
                            onChange={updateLngContent}
                        />
                    </div>
                    {/* <CheckboxWithLabel
                        label={`Выводить иконку "верифицирован"`}
                        isChecked={content.showIconVerification}
                        fieldName="showIconVerification"
                        onChange={updateLngContent}
                    /> TEMP COMMIT */}

                    <div className="filed-block">
                        {content.icon ? (
                            <ImageWithModal
                                src={`/images/${content.icon}`}
                                onDelete={() =>
                                    updateLngContent("icon", "", true)
                                }
                            />
                        ) : (
                            <ImageUploader
                                label="Иконка"
                                onChange={updateLngContent}
                                fieldName="icon"
                                imageType={ImageType.ICON}
                            />
                        )}
                    </div>

                    <ScreensBlock
                        screens={content.screens}
                        updateLngContent={updateLngContent}
                    />
                    <div className="filed-block">
                        <TextareaWithLabel
                            label="Описание"
                            value={content.desc}
                            fieldName="desc"
                            onChange={updateLngContent}
                            minHeight={140} // 8 lines visible
                        />
                        <div
                            className="tip"
                            style={{ marginTop: "2px" }}
                        >{`Доступные макросы (опционально): {pwa_name} - Название PWA приложения. {date} - текущая дата. {date-3} - дата на три дня раньше текущей. {$100} - эквивалент $100 в локальной валюте.`}</div>
                    </div>
                    <div className="four-fields tags-block">
                        <CreatableSelectWithLabel
                            options={TAGS_PWA}
                            label="Теги"
                            fieldName="tags"
                            placeholder="Напишите тег"
                            value={content.tags}
                            onChange={updateLngContent}
                        />
                    </div>
                    <div className="four-fields">
                        <InputWithLabel
                            label="Ссылка на видео"
                            value={content.videoUrl}
                            fieldName="videoUrl"
                            onChange={updateLngContent}
                        />
                    </div>
                    <div className="filed-block"></div>
                    <Accordion title="Oкно установки" className="filed-block">
                        <div className="four-fields window-settings-block">
                            <SelectWithLabel
                                options={WINDOW_INSTALL_OPTIONS}
                                value={content.windowInstallType}
                                onChange={updateLngContent}
                                fieldName="windowInstallType"
                                label="Окно установки"
                            />
                            {content.windowInstallType === "withScreens" && (
                                <TextareaWithLabel
                                    value={content.windowInstallDesc}
                                    onChange={updateLngContent}
                                    fieldName="windowInstallDesc"
                                    label="Описание над скриншотом"
                                />
                            )}
                        </div>
                    </Accordion>
                    <Accordion title="Что нового" className="filed-block">
                        <div className="four-fields news-block">
                            <SelectWithLabel
                                options={DATE_SELECT_OPTIONS.filter(
                                    ({ value }) => value !== "25+"
                                )}
                                value={data.lastUpdated}
                                onChange={updateDesc}
                                label="Последнее обновление"
                                fieldName="lastUpdated"
                            />
                            <TextareaWithLabel
                                label="Что нового"
                                value={content.news}
                                fieldName="news"
                                onChange={updateLngContent}
                            />
                        </div>
                    </Accordion>
                </div>
            )}
            <div
                className="static-desc"
                style={{
                    display:
                        data?.descriptions &&
                        Object.keys(data?.descriptions).length
                            ? "block"
                            : "none",
                }}
            >
                <div className="four-fields">
                    <SelectWithLabel
                        label="Возраст"
                        value={data.age}
                        options={AGE_OPTIONS}
                        fieldName="age"
                        onChange={updateDesc}
                    />
                    <InputWithLabel
                        label="Загрузок"
                        type="number"
                        value={data.download}
                        fieldName="download"
                        onChange={updateDesc}
                        min={1}
                        disallowedSymbols={DISALLOWED_NUMBER_SYMBOLS}
                    />
                    <InputWithLabel
                        label="Отзывов"
                        value={data.reviews}
                        type="number"
                        fieldName="reviews"
                        onChange={updateDesc}
                        min={1}
                        disallowedSymbols={DISALLOWED_NUMBER_SYMBOLS}
                    />
                    <InputWithLabel
                        label="Рейтинг"
                        type="number"
                        value={data.rating}
                        onBlur={() =>
                            updateDesc("rating", formatRating(+data.rating))
                        }
                        fieldName="rating"
                        onChange={updateDesc}
                        min={1}
                        max={5}
                        step="0.1"
                        disallowedSymbols={DISALLOWED_NUMBER_SYMBOLS}
                    />
                </div>

                <div className="four-fields">
                    <InputWithLabel
                        label="Размер файла, Мб"
                        type="number"
                        value={data.btnDownSize}
                        fieldName="btnDownSize"
                        onChange={updateDesc}
                        min={1}
                        disallowedSymbols={DISALLOWED_NUMBER_SYMBOLS}
                    />
                </div>
                <Accordion title="Контакты" className="filed-block">
                    <div className="four-fields">
                        <InputWithLabel
                            label="Сайт"
                            value={data.contWebsite}
                            fieldName="contWebsite"
                            onChange={updateDesc}
                        />
                        <InputWithLabel
                            label="E-mail"
                            value={data.contEmail}
                            fieldName="contEmail"
                            onChange={updateDesc}
                        />
                        <InputWithLabel
                            label="Адрес"
                            value={data.contAddress}
                            fieldName="contAddress"
                            onChange={updateDesc}
                        />
                        <InputWithLabel
                            label="Политика"
                            value={data.contPrivacy}
                            fieldName="contPrivacy"
                            onChange={updateDesc}
                        />
                    </div>
                </Accordion>
            </div>
        </div>
    );
};

export default Description;
