import BasePage from "../base/BasePage";
import {RestrictedArea} from "../../wrappers/RestrictedArea";
import {UserGroup} from "../../context/User";
import {Link} from "react-router-dom";
import Button from "../../components/UI/Button";
import SelectClient from "../../widgets/admin/SelectClient";
import {formatClientName, formatCurrency, formatDateTime, formatPercent, pick} from "../../common/commonUtil";
import React from "react";
import AdminGameStats from "./AdminGameStats";
import axios from "axios";
import {getResponseErrorsList} from "../../util/util";
import {margin} from "clean-css/lib/optimizer/level-1/property-optimizers";
import Pages from "../../widgets/Pages";

class AdminDeviceLogs extends BasePage {
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            executing: false
        }
    }

    componentDidMount() {
    }

    async onSubmit(event) {
        event.preventDefault();

        if (!this.busy) {
            try {
                this.busy = true;
                this.setState({executing: true});
                let res = await axios.post('/getdevicelogs', {...pick(this.state,
                        ['domain','sub_key_1','sub_value_1','sub_key_2','sub_value_2','device_id']) });
                this.setState({executing: false, logs: res.data.logs, errors: {}});
            } catch (err) {
                this.setState({executing: false, logs: [], errors: getResponseErrorsList(err)});
            }
            this.busy = false;
        }
    }

    renderPage() {
        const { executing, logs, device_id } = this.state;

        const inputField = (fieldName,description,tip,wide=false,disabled=false) => (
            [
            <label htmlFor={fieldName} style={{margin: '8px'}}>{description + (description ? ':' : '')}</label>,
            <input id={fieldName} name={fieldName} type="text" onChange={this.onFieldChanged.bind(this,fieldName)}
                   value={this.state[fieldName]} placeholder={tip} style={{width: wide ? '360px' : '120px',
                opacity: disabled ? 0.3 : 1.0}} disabled={disabled}/>
            ]
        );

        let lastDeviceID;

        return (
            <RestrictedArea allowedTo={UserGroup.DEVELOPER}>
                <div className="page">
                    <h1>Логи устройства</h1>
                    <form className='left-align' onSubmit={this.onSubmit.bind(this)} style={{maxWidth: 'none'}}>
                        <ul>
                            <li className="field">
                                {inputField("domain","Домен","",true, !!device_id)}
                            </li>
                            <li className="field" style={{marginLeft: '60px'}}>
                                {inputField("sub_key_1","", "Саб", false, !!device_id)}
                                {inputField("sub_value_1","", "Значение", false, !!device_id)}
                            </li>
                            <li className="field" style={{marginLeft: '60px'}}>
                                {inputField("sub_key_2","", "Саб", false, !!device_id)}
                                {inputField("sub_value_2","", "Значение", false, !!device_id)}
                            </li>
                            <li className="field">
                                {inputField("device_id","Device ID","",true)}
                                {this.renderError("device_id")}
                            </li>

                            <li className="submit">
                                <div>
                                    <div>
                                        <Button
                                            type='submit'
                                            withoutRounded
                                            title='Показать логи'
                                            isLoading={executing}
                                        />
                                    </div>
                                    {this.renderRestErrors()}
                                    {this.state.execMsg &&
                                        <div className="tinyTip holder">{this.state.execMsg}</div>
                                    }
                                </div>
                            </li>
                        </ul>
                    </form>

                    <div style={{marginTop: '30px'}}>
                        <div className='table-responsive perfect-scrollbar'>
                            <table className="table" style={{minWidth: 700}}>
                                <thead>
                                <tr>
                                    <th>Устройство</th>
                                    <th>Время</th>
                                    <th>Событие</th>
                                    <th>Параметры события</th>
                                </tr>
                                </thead>
                                <tbody>
                                {logs && logs.map((r,i) => {
                                    const deviceIDChanged = lastDeviceID !== r.device_id;
                                    lastDeviceID = r.device_id;
                                    return <tr key={i} style={{ marginTop: deviceIDChanged ? '10px' : '2px'}}>
                                        <td style={{fontSize: '10px'}}>{r.device_id}</td>
                                        <td>{formatDateTime(r.time)}</td>
                                        <td>{r.event_name}</td>
                                        <td>{r.event_data}</td>
                                    </tr>
                                })}
                                {logs && logs.length >= 100 &&
                                    <tr><td style={{fontSize: '16px'}}>[ .......конкретизируйте сабы....... ]</td></tr>
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </RestrictedArea>
        );
    }

}

export default AdminDeviceLogs;