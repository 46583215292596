import { Link } from "react-router-dom";
import Button from "../../../components/UI/Button";
import PWAItem from "./PWAItem";
import { useContext, useEffect, useState } from "react";
import Pages from "../../../widgets/Pages";
import axios from "axios";
import { StoreID, UserGroup } from "../../../common/commonTypes";
import WarningModal from "../../../components/UI/WarningModal";
import { UserContext } from "../../../context/User";

const initConfirmModalState = {
    isVisible: false,
    onClose: () => {},
    onConfirm: () => {},
};

const PWAList = ({ userId }) => {
    const [pwaList, setPwaList] = useState(null);
    const [page, setPage] = useState(1);
    const [isLastPage, setIsLastPage] = useState(false);
    const [confirmModal, setConfirmModal] = useState(initConfirmModalState);

    const user = useContext(UserContext);

    const fetchData = async () => {
        setPwaList(null);

        try {
            const sendData = {
                page,
                appType: StoreID.PWA,
            };

            if (userId) {
                sendData.user_id = userId;
            }

            const { data } = await axios.post("/applist", sendData);

            setPwaList(data.apps);
            setIsLastPage(data.isLast);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchData();
    }, [page, userId]);

    const handlerChangePage = (nextPage) => {
        setPage(nextPage);
    };

    const showDuplicateModal = (pwaId) => {
        setConfirmModal({
            isVisible: true,
            onClose: () => {
                setConfirmModal(initConfirmModalState);
            },
            onConfirm: async () => {
                await axios.post("/app_duplicate", { app_id: pwaId });
                setConfirmModal(initConfirmModalState);
                fetchData();
            },
        });
    };

    return (
        <div className="pwa-block" id="pwa-block">
            <WarningModal
                isVisible={confirmModal.isVisible}
                onClose={confirmModal.onClose}
                anchorContainerID="pwa-block"
                onConfirm={confirmModal.onConfirm}
                text="<h2 style='color: red'>В процессе разработки</h2>Дублировать PWA?"
                confirmText="Дублировать"
            />
            <Link to={`/newpwa`} className="new-pwa-link">
                <Button title="Создать" withoutRounded fullFill />
            </Link>
            <div className="pwa-list">
                {pwaList?.map((item) => (
                    <PWAItem
                        key={item.id}
                        pwaData={item}
                        showDuplicateModal={showDuplicateModal}
                        isShowDuplicateBtn={user?.hasPermission(
                            UserGroup.DEVELOPER
                        )}
                    />
                ))}
            </div>
            <div className="footer">
                <Pages
                    style={{ margin: "0 auto", width: "auto" }}
                    handler={handlerChangePage}
                    current={page}
                    isLast={isLastPage}
                />
            </div>
        </div>
    );
};

export default PWAList;
