import { memo, useMemo } from "react";
import Select from "react-select";
import { Tooltip as ReactTooltip } from "react-tooltip";
import ClueIcon from "../icons/ClueIcon";

const SelectWithLabel = ({
    label,
    value,
    onChange,
    fieldName,
    options,
    isMulti,
    tooltipText,
}) => {
    const initValue = useMemo(() => {
        if (isMulti) {
            return options.filter((opt) => value?.includes(opt.value));
        }

        return options.find((opt) => opt.value === value) || "";
    }, [value, isMulti]);

    return (
        <div className="filed-block">
            <label>
                {label}
                {tooltipText && (
                    <span
                        className="clue-icon-wrapper"
                        data-tooltip-id={"SelectWithLabel" + label}
                    >
                        <ClueIcon />
                    </span>
                )}
            </label>
            <Select
                className="select small"
                value={initValue}
                onChange={(newValue) => {
                    onChange(
                        fieldName,
                        isMulti
                            ? newValue.map((item) => item.value)
                            : newValue.value
                    );
                }}
                options={options}
                isMulti={isMulti}
            />
            {tooltipText && (
                <ReactTooltip
                    id={"SelectWithLabel" + label}
                    type="light"
                    effect="solid"
                    style={{ zIndex: 9999 }}
                    content={tooltipText} // Указываем HTML здесь
                />
            )}
        </div>
    );
};

export default SelectWithLabel;
