import { ImageType } from "../../common/commonTypes";
import ImageUploader from "../../components/UI/ImageUploader";
import ImageWithModal from "../../components/UI/ImageWithModal";

const ScreensBlock = ({ screens, updateLngContent }) => {
    const addScreen = (filedName, link) => {
        const screensCopy = screens ? [...screens] : [];
        screensCopy.push(link);

        updateLngContent("screens", screensCopy);
    };

    const deleteScreen = (link) => {
        const screensCopy = screens.filter((item) => item !== link);
        updateLngContent("screens", screensCopy, true);
    };

    return (
        <div className="screens-block filed-block">
            {screens?.map((link) => (
                <ImageWithModal
                    key={link}
                    src={`/images/${link}`}
                    onDelete={() => deleteScreen(link)}
                />
            ))}
            <ImageUploader
                label="Скриншот"
                onChange={addScreen}
                fieldName="icon"
                imageType={ImageType.SCREENSHOT}
            />
        </div>
    );
};

export default ScreensBlock;
